import './style/StoreContent.css';
import React,  { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Action from '../block/Action';
import Label from '../block/Label';
import Product from '../../page/Product';
import { getImgUrl } from '../../util/ImageSource';
import { formatToCurrency, formatNumberWithCommas, formatPercentWithCommas } from '../../util/Calculate';
import Banner from '../block/Banner';
import Cart from './Cart';
import NoResultsIcon from '../../media/no-results@3x.png';
import AnalogDevices from '../../media/mfg-logos/analog-devices.png';
import Panasonic from '../../media/mfg-logos/panasonic.png';
import Mastech from '../../media/mfg-logos/mastech.png';
import KPS from '../../media/mfg-logos/kps.jpeg';
import Renesas from '../../media/mfg-logos/renesas.png';
import Onsemi from '../../media/mfg-logos/onsemi.png';
import Icon from '../block/Icon';
import Image from '../block/Image';
import Tile from '../block/Tile';
import ChipRounded from '../block/ChipRounded';
const StoreContent = (
    { 
        showCart,
        cart,
        handleViewProduct,
        currentProducts,
        handleCategoryChangeNew,
        categoryPaths,
        currentProduct,
        addToCart,
        searchInputValue,
        setShowSuccessToast,
        alsoViewedProducts,
        storeProducts,
        showAddedToCartToast,
        setShowAddedToCartToast
    }
) => {
    const mfgLogos = [
        {
            manufacturer: "Analog Devices",
            url: "analog%20devices",
            image: AnalogDevices
        },
        {
            manufacturer: "Renesas",
            url: "renesas",
            image: Renesas
        },
        {
            manufacturer: "KPS",
            url: "kps",
            image: KPS
        },
        {
            manufacturer: "Panasonic",
            url: "panasonic",
            image: Panasonic
        },
        {
            manufacturer: "Mastech",
            url: "mastech",
            image: Mastech
        },
        {
            manufacturer: "Onsemi",
            url: "onsemi",
            image: Onsemi
        }
    ];

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [captchaComplete, setCaptchaComplete] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const { pathname } = useLocation();

    const validateEmail = (email) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    };

    const validateFields = () => {
        let valid = true;

        if (name?.length === 0 || email?.length === 0 || addressLine1?.length === 0 || city?.length === 0 || zip?.length === 0 || state?.length === 0 || country?.length === 0 || !captchaComplete) {
            valid = false;
        }

        if (!validateEmail(email)) {
            valid = false;
        }

        setSubmitEnabled(valid);
    };

    useEffect(() => {
        validateFields();
    }, [email, name, addressLine1, addressLine2, city, zip, state, country, captchaComplete]);

    return (
        <div className="flex vertical mobile-width-100 flex-1 v-gap-m pad-m no-pad-bot no-pad-right no-pad-left">
            {(!currentProduct && !showCart && pathname === "/") && (
                <div className="flex vertical pad-m no-pad-top v-gap-m">
                    <Label content="Featured Manufacturers" size="m" bold={true} displayFont={true} classes="pad-m no-pad-bot no-pad-left" />
                    <h2 className='hidden-section'>
                        Featured Manufacturers
                    </h2>
                    <div className="grid-featured">
                        {mfgLogos.map((manufacturer, index) => (
                            <Tile onClick={() => navigate(`/${manufacturer.url}`)} content={(
                                <div className="flex vertical justify-center pad-m height-100">
                                    <Image source={manufacturer.image} fullWidth={true} />
                                    <h3 className='hidden-section'>{manufacturer.manufacturer}</h3>
                                </div>
                            )} />
                        ))}
                    </div>
                </div>
            )}
            {!showCart && <div className="flex vertical v-gap-m">
                <div className={`flex horizontal pad-m ${(!showCart && !currentProduct && pathname === "/") ? "no-pad-top" : ""} no-pad-bot mobile-hide h-gap-s`}>
                    <Action label="All Products" onClick={() => navigate('/')} breadcrumb={true} hideCaret={true} active={categoryPaths?.length === 0} />
                    {categoryPaths?.map((categoryPath, index) => (
                        <Action key={categoryPath} index={index} label={decodeURIComponent(categoryPath.replaceAll("-", " ")?.toLowerCase()) === currentProduct?.marketMpn?.toLowerCase() ? categoryPath.replaceAll("-", " ")?.toUpperCase() : categoryPath.replaceAll("-", " ")} onClick={() => handleCategoryChangeNew(categoryPath)} breadcrumb={true} active={index === categoryPaths.length - 1} />
                    ))}
                </div>
            </div>}
            {!showCart && !currentProduct && (
                <div className="flex horizontal pad-m h-gap-m no-pad-bot desktop-hide">
                    <Label content="All Products" displayFont={true} bold="true" size="m" flex={1} />
                    <h2 className='hidden-section'>
                        All Products
                    </h2>
                </div>
            )}
            <div className={`${(!showCart && !currentProduct) ? "grid" : "flex-vertical flex-1 h-gap-xs"} pad-m no-pad-top`}>
                {showCart ? (
                    <Cart items={cart} handleViewProduct={handleViewProduct} setShowSuccessToast={setShowSuccessToast} />
                ) : currentProduct ? (
                    <Product currentProduct={currentProduct} addToCart={addToCart} alsoViewedProducts={alsoViewedProducts} cart={cart} handleViewProduct={handleViewProduct} showAddedToCartToast={showAddedToCartToast} setShowAddedToCartToast={setShowAddedToCartToast} />
                ) : currentProducts?.map((product, index) => (
                    <Tile
                        key={index + product.marketMpn}
                        index={index + product.marketMpn}
                        onClick={() => handleViewProduct(product)}
                        content={
                            <div className="flex vertical pad-m height-100 space-between">
                                <h3 className='hidden-section'>
                                    {`${product.marketManufacturer} ${product.marketMpn}`}
                                </h3>
                                <div className="flex vertical">
                                    <Label content={product.marketManufacturer || ""} size="s" bold={true} mono={true} displayFont={true} secondary={true} />
                                    <Label content={product.marketMpn} size="m" mono={true} />
                                </div>
                                <Image source={getImgUrl(product)} altTag={product.marketMpn} fullWidth={true} overlayContent={product.percentOfMarketPrice !== 0 && <ChipRounded positive={true} small={true} label={`${formatPercentWithCommas(product.percentOfMarketPrice)} Below Market`} />}/>
                                <div className="flex vertical">
                                    <Label content={`${product.quantity} Units`} size="s" bold={true} mono={true} displayFont={true} secondary={true} />
                                    <Label content={formatToCurrency(product.price)} size="m" mono={true} />
                                </div>
                            </div>
                        } />
                ))}
            </div>
            {(!showCart && !currentProduct && currentProducts?.length === 0) && (
                <div className="flex vertical align-center pad-xl v-gap-s">
                    <Icon icon={NoResultsIcon} size={48} color="#6f7aa5" />
                    <Label content="No products found matching that search" size="m" bold={true} displayFont={true} center={true} secondary={true} />
                </div>
            )}
        </div>
    );
};

export default StoreContent;