import './style/StoreContent.css';
import React,  { useState, useEffect } from 'react';
import Label from '../block/Label';
import { formatToCurrency, formatNumberWithCommas } from '../../util/Calculate';
import { getImgUrl } from '../../util/ImageSource';
import { useDispatch } from 'react-redux';
import Button from '../block/Button';
import Divider from '../block/Divider';
import Input from '../block/Input';
import ReCAPTCHA from "react-google-recaptcha";
import { submitOffer } from '../../redux/temporarySlice';
import { setCart } from '../../redux/localSlice';
import SimpleLoader from '../block/SimpleLoader';
import { useSelector } from 'react-redux';
import TrashIcon from '../../media/trash@3x.png';
import CartDisplayIcon from '../../media/cart-display@3x.png';
import availablePaths from './3d-image-paths.json';
import Icon from '../block/Icon';
import Image from '../block/Image';
import Tile from '../block/Tile';

const Cart = (
    { 
        items,
        handleViewProduct,
        setShowSuccessToast
    }
) => {
    const imagePaths = availablePaths;
    const sitekey = window.location?.href?.includes('localhost') ? '6LeRb4AqAAAAAMi0AgNFSMFxyw9bo5q2wePZ9p3u' : '6LeVWn8qAAAAAHHCHAzhrmTQjuadBu9fzePxRzyo'
    const dispatch = useDispatch();
    const temporary = useSelector((state) => state.temporary);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [captchaComplete, setCaptchaComplete] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState("");
    const [awaitingSubmittedOffer, setAwaitingSubmittedOffer] = useState(false);
    const [errorSubmittingOffer, setErrorSubmittingOffer] = useState(null);

    const handleCaptchaChange = (value) => {
        if (value) {
            setCaptchaComplete(true);
            setCaptchaResponse(value);
        }
    };

    const validateEmail = (email) => {
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    };

    const validateFields = () => {
        let valid = true;

        if (name?.length === 0 || email?.length === 0 || addressLine1?.length === 0 || city?.length === 0 || zip?.length === 0 || state?.length === 0 || country?.length === 0 || !captchaComplete) {
            valid = false;
        }

        if (!validateEmail(email)) {
            valid = false;
        }

        setSubmitEnabled(valid);
    };

    useEffect(() => {
        validateFields();
    }, [email, name, addressLine1, addressLine2, city, zip, state, country, captchaComplete]);

    useEffect(() => {
        if (temporary?.submittingOffer) {
            setAwaitingSubmittedOffer(true);
        }
    }, [temporary?.submittingOffer]);

    useEffect(() => {
        if (temporary?.errorSubmittingOffer !== null && awaitingSubmittedOffer) {
            setErrorSubmittingOffer(temporary.errorSubmittingOffer);
        } else if (temporary?.submittedOffers?.length > 0 && awaitingSubmittedOffer) {
            setEmail("");
            setName("");
            setAddressLine1("");
            setAddressLine2("");
            setCity("");
            setZip("");
            setState("");
            setCountry("");
            setCaptchaResponse("");
            setErrorSubmittingOffer(null);
            setShowSuccessToast(true);
            dispatch(setCart([]));
        }
        setAwaitingSubmittedOffer(false);
    }, [temporary?.errorSubmittingOffer, temporary?.submittedOffers]);

    const handleOrderRequest = () => {
        if (!submitEnabled) {
            return;
        }

        let offer = {
            recaptcha_response: captchaResponse,
            line_items: items.map((cartItem) => {
                return {
                    mpn: cartItem.product.marketMpn,
                    manufacturer: cartItem.product.marketManufacturer,
                    quantity: cartItem.quantity,
                    unit_price: cartItem.product.price,
                    currency: "USD"
                };
            }),
            buyer: {
                email: email,
                shipping_address: {
                    line1: addressLine1,
                    line2: addressLine2,
                    city: city,
                    region: state,
                    zip: zip,
                    country: country
                }
            }
        }

        dispatch(submitOffer({ offer }));
    };

    const handleDeleteCartItem = (e, cartItem) => {
        e.stopPropagation();
        dispatch(setCart(items.filter((item) => item.product.marketManufacturer !== cartItem.product.marketManufacturer && item.product.marketMpn !== cartItem.product.marketMpn)));
    };

    const handleQuantityInputChange = (e, cartItem) => {
        const value = e.target.value;
        // Use a regex to ensure all input is numeric
        if (/^\d+$/.test(value) && parseInt(value) <= cartItem.product?.quantity) {
            dispatch(setCart(items.map((item) => item.product.marketMpn === cartItem.product.marketMpn ? { ...item, quantity: e.target.value } : item)));
        }
    };

    const lineItemDetails = (format, cartItem) => {
        const details = (
            <>
                <div className={`flex horizontal mobile-vertical v-gap-xs flex-1 align-center mobile-hide`}>
                    {format === "mobile" && <Label content="Quantity" size="m" displayFont={true} bold="true" />}
                    <Input label="Quantity" type="number" width100={true} value={cartItem.quantity} onChange={(e) => handleQuantityInputChange(e, cartItem)} />
                </div>
                <div className={`flex horizontal mobile-vertical v-gap-xs flex-1 align-center ${format === "desktop" ? "mobile-hide" : ""}`}>
                    {format === "mobile" && <Label content="Lead Time" size="m" displayFont={true} bold="true" />}
                    <Label content="5-7 Days" size="m" mono={true} />
                </div>
                <div className={`flex horizontal mobile-vertical v-gap-xs flex-1 align-center ${format === "desktop" ? "mobile-hide" : ""}`}>
                    {format === "mobile" && <Label content="Unit Price" size="m" displayFont={true} bold="true" />}
                    <Label content={`${formatToCurrency(cartItem.product.price)}`} size="m" mono={true} />
                </div>
                <div className={`flex horizontal mobile-vertical v-gap-xs flex-1 space-between align-center ${format === "desktop" ? "mobile-hide" : ""}`}>
                    {format === "mobile" && <Label content="Extended Price" size="m" displayFont={true} bold="true" />}
                    <Label content={formatToCurrency(cartItem.product.price * cartItem.quantity)} size="m" mono={true} />
                    <div className="flex pad-m mobile-hide">
                        <Button icon={TrashIcon} onClick={(e) => handleDeleteCartItem(e, cartItem)} />
                    </div>
                </div>
            </>
        )

        if (format === "mobile") {
            return (
                <div className="flex vertical flex-1 desktop-hide v-gap-m">
                    {details}
                </div>
            );
        } else {
            return details;
        }
    }

    return (
        <div className="flex vertical mobile-footer-pad v-gap-m">
            <Label content="Shopping Cart" size="m" bold={true} displayFont={true} classes="mobile-hide pad-m no-pad-bot no-pad-left" />
            <h2 className='hidden-section'>
                Shopping Cart
            </h2>
            <div className="flex vertical v-gap-xs">
                {items?.length > 0 && <div className={`flex horizontal h-gap-m pad-m no-pad-top no-pad-bot transparent-border mobile-hide`}>
                    <Label content="Product Details" displayFont={true} bold="true" size="s" flex={2} />
                    <Label content="Quantity" displayFont={true} bold="true" size="s" flex={1} />
                    <Label content="Lead Time" displayFont={true} bold="true" size="s" flex={1} />
                    <Label content="Unit Price" displayFont={true} bold="true" size="s" flex={1} />
                    <Label content="Extended Price" displayFont={true} bold="true" size="s" flex={1} />
                </div>}
                <div className="flex vertical keystone-card overflow-hidden">
                    {items?.map((cartItem, index) => (
                        <Tile
                            key={index + cartItem.product.marketMpn}
                            index={index + cartItem.product.marketMpn}
                            rowStyle={true}
                            content={
                                <div className="flex vertical pad-m v-gap-m">
                                    <div className="flex horizontal height-100 h-gap-m align-center">
                                        <div className="flex horizontal mobile-vertical flex-2 align-center h-gap-m">
                                            <Image source={getImgUrl(cartItem.product)} altTag={cartItem.product.marketMpn} width="small" classes="mobile-cart-image" onClick={() => handleViewProduct(cartItem.product)} />
                                            <div className="flex vertical">
                                                <Label content={cartItem.product.marketManufacturer || ""} size="s" bold={true} mono={true} displayFont={true} secondary={true} />
                                                <Label content={cartItem.product.marketMpn} size="m" mono={true} />
                                                <Label content={cartItem.product.description.slice(0, 80) + "..."} size="s" mono={true} secondary={true} classes="mobile-hide" />
                                            </div>
                                        </div>
                                        {lineItemDetails("desktop", cartItem)}
                                        {lineItemDetails("mobile", cartItem)}
                                    </div>
                                    <div className="flex vertical desktop-hide v-gap-xs">
                                        <Label content="Quantity" size="m" displayFont={true} bold="true" />
                                        <Input label="Quantity" type="number" width100={true} value={cartItem.quantity} onChange={(e) => handleQuantityInputChange(e, cartItem)} />
                                    </div>
                                </div>
                            }
                        />
                    ))}
                    {items?.length === 0 && (
                        <div className="flex vertical align-center pad-xl v-gap-m">
                            <Icon icon={CartDisplayIcon} size={44} color="#6f7aa5" />
                            <div className="flex vertical v-gap-xs">
                                <Label content="Your cart is empty" size="m" bold={true} displayFont={true} center={true} />
                                <Label content="Items added to your cart will show up here." size="s" bold={true} displayFont={true} center={true} secondary={true} />
                            </div>
                            <Button label ="Continue Shopping" onClick={() => window.location.href = "/"} brand={true} />
                        </div>
                    )}
            </div>
            </div>
            {items?.length > 0 && (
                <div className="flex horizontal relative">
                    <div className="flex horizontal flex-1 mobile-hide"></div>
                    <div id="order-request" className="flex vertical keystone-card-secondary">
                        <div className="flex horizontal h-gap-xl pad-m space-between white-bg">
                            <Label content="Order Request" size="m" bold={true} displayFont={true}/>
                        </div>
                        <Divider vertical={false} />
                        <div className="flex vertical pad-m no-pad-bot v-gap-s">
                            <Label content="Contact Information" size="s" bold={true} displayFont={true}/>
                            <div className="flex vertical v-gap-xs">
                                <Input placeholder="Name" onWhite={false} flex={true} onChange={(e) => setName(e.target.value)} />
                                <Input placeholder="Email" type="email" onWhite={false} flex={true} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className="flex vertical pad-m v-gap-s">
                            <Label content="Shipping Information" size="s" bold={true} displayFont={true}/>
                            <div className="flex vertical v-gap-xs">
                                <Input placeholder="Address line 1" onWhite={false} flex={true} onChange={(e) => setAddressLine1(e.target.value)} />
                                <Input placeholder="Address line 2" onWhite={false} flex={true} onChange={(e) => setAddressLine2(e.target.value)} />
                                <div className="flex horizontal flex-1 width-100 h-gap-xs">
                                    <Input placeholder="City" onWhite={false} flex={true} onChange={(e) => setCity(e.target.value)} />
                                    <Input placeholder="Zip" onWhite={false} flex={true} onChange={(e) => setZip(e.target.value)} />
                                </div>
                                <div className="flex horizontal flex-1 width-100 h-gap-xs">
                                    <div className="flex horizontal flex-1 h-gap-xs">
                                        <Input placeholder="State" onWhite={false} flex={true} onChange={(e) => setState(e.target.value)} />
                                    </div>
                                    <div className="flex horizontal flex-1 h-gap-xs">
                                        <Input placeholder="Country" onWhite={false} flex={true} onChange={(e) => setCountry(e.target.value)} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="flex vertical pad-m v-gap-s no-pad-top">
                            <ReCAPTCHA
                                sitekey={sitekey} 
                                onChange={handleCaptchaChange} />
                        </div>
                        <Divider vertical={false} />
                        <div className="flex vertical space-between pad-m v-gap-m white-bg">
                            <div className="flex horizontal h-gap-s space-between">
                                <div className="flex vertical">
                                    <Label content="Total:" bold={true} size="m" displayFont={true} />
                                    <Label content="No payment is required at this time." size="s" bold={true} displayFont={true} secondary={true} />
                                </div>
                                <Label content={formatToCurrency(items.reduce((acc, cartItem) => acc + (cartItem.product.price * cartItem.quantity), 0))} size="m" mono={true} />
                            </div>
                            <Button label="Submit Order Request" brand={true} flex={true} large={true} disabled={!submitEnabled} onClick={handleOrderRequest} />
                            <div className="flex vertical">
                                <Label content="Shipping quote and purchase order will be sent upon confirmation from the storing manufacturer." size="s" bold={true} displayFont={true} secondary={true} />
                            </div>
                        </div>
                        <SimpleLoader loading={temporary.submittingOffer} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Cart;