import './style/CollapsableSection.css';
import React, {useState} from 'react';
import { } from '../../redux/localSlice';
import Label from './Label';
import Icon from './Icon';
import DownCaret from '../../media/down-caret@3x.png';
import Divider from './Divider';

const CollapsableSection = ({ index, label, content, classes = ""}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleClick = () => {
        // Implement the collapse/expand functionality
        setIsCollapsed(!isCollapsed);
    }
    return (
        <div key={index} className={`flex vertical ${!isCollapsed ? "row" : "row-clickable"} row flex-1 secondary ${classes}`}>
            <div className="flex horizontal align-center space-between pad-s clickable" onClick={handleClick}>
                <Label content={label} size="s" bold={true} displayFont={true} />
                <Icon icon={DownCaret} size={10} rotate={!isCollapsed ? "180" : "0"} />
            </div>
            {!isCollapsed &&  (
                <>
                    <Divider />
                    {content}
                </>
            )}
        </div>
    );
};

export default CollapsableSection;